@tailwind base;
@tailwind components;
@tailwind utilities;

.tagify {
  --tags-border-color: rgb(5 150 105 / var(--tw-ring-opacity)) !important;
  padding: 4px;

  .tagify__tag,
  .tagify__input {
    margin: 3px;
  }
}
